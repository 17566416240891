<template>
  <div class="full-recipe">
    <div v-if="item" class="container">
      <div class="full-recipe__nav">
        <locale-router-link to="recipes" class="crumbs-link recipes-link">
          {{ $t("home") }}
        </locale-router-link>
        <locale-router-link
          to="cooking"
          class="crumbs-link cooking-with-magnum"
        >
          {{ $t("cookingWithMagnum") }}
        </locale-router-link>
        <locale-router-link
          to="recipes"
          class="crumbs-link cooking-with-magnum"
        >
          {{ $t("recipes") }}
        </locale-router-link>
        <a class="crumbs-link full-recipe-link">{{ item.attributes.title }}</a>
      </div>
      <div class="full-recipe__img">
        <img :src="getImage" alt="recipe image" />
        <div class="full-recipe__header">
          <h1 class="full-recipe__title">
            {{ item.attributes.title }}
          </h1>
          <div class="full-recipe__info-wrapper">
            <div v-if="item.attributes.hits" class="full-recipe__views">
              {{ item.attributes.hits }}
            </div>
            <div class="full-recipe__time">{{ getDate }}</div>
          </div>
        </div>
      </div>

      <!-- full-recipe__review -->
      <div class="full-recipe__review">
        <!-- full-recipe__review-wrapper -->
        <div class="full-recipe__review-wrapper">
          <!-- full-recipe__descr -->
          <p v-if="item.attributes.content" class="full-recipe__descr">
            {{ item.attributes.content }}
          </p>

          <!-- recipe filter -->
          <div v-if="item.attributes" class="full-recipe__filter">
            <div
              v-if="item.attributes.recipe_details"
              class="recipe-block__filter"
            >
              <div class="recipe-block__filter-header">
                <div class="recipe-block__filter-title">
                  {{ $t("ingredients") }}
                </div>
                <div class="recipe-block__portions">
                  <div class="recipe-block__portions-title">
                    {{ $t("portions") }}
                  </div>
                  <div class="recipe-block__portions-quantity">
                    <button
                      class="recipe-block__portions-minus"
                      @click="updateIngredient('minus')"
                    >
                      –
                    </button>
                    <p class="recipe-block__portions-amount">
                      {{ countPortion }}
                    </p>
                    <button
                      class="recipe-block__portions-plus"
                      @click="updateIngredient('plus')"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="item.attributes.recipe_details"
                class="recipe-block__filter-items"
              >
                <div
                  v-for="(item, index) in item.attributes.recipe_details"
                  :key="index"
                  class="recipe-block__filter-item"
                >
                  <div class="recipe-block__filter-item-name">
                    {{ item.name }}
                  </div>
                  <span></span>
                  <div class="recipe-block__filter-item-info">
                    {{ item.quantityUpadated }} {{ $t(item.typeIngredient) }}
                  </div>
                </div>
              </div>
              <!-- recipe filter -->
            </div>
          </div>

          <div
            v-if="item.attributes.author.data"
            class="full-recipe__author-wrapper"
          >
            <img
              :src="getAutorImage(item)"
              alt="author image"
              class="full-recipe__author-img"
            />
            <div class="full-recipe__author-info">
              <div class="full-recipe__author-title">
                {{ $t("recipeAuthor") }}
              </div>
              <div class="full-recipe__author-name">
                {{ item.attributes.author.data.attributes.name }}
              </div>
              <div class="full-recipe__author-position">
                {{ item.attributes.author.data.attributes.whois }}
              </div>
            </div>
          </div>

          <div
            v-if="item.attributes.nutrition_facts"
            class="full-recipe__ingredients"
          >
            <div class="full-recipe__ingredients-item">
              <div class="full-recipe__ingredients-name">
                {{ $t("calories") }}
              </div>
              <div class="full-recipe__ingredients-wight">
                {{ item.attributes.nutrition_facts.cals }} {{ $t("ccal") }}
              </div>
            </div>
            <div class="full-recipe__ingredients-item">
              <div class="full-recipe__ingredients-name">
                {{ $t("protein") }}
              </div>
              <div class="full-recipe__ingredients-wight">
                {{ item.attributes.nutrition_facts.protein }} г
              </div>
            </div>
            <div class="full-recipe__ingredients-item">
              <div class="full-recipe__ingredients-name">{{ $t("fats") }}</div>
              <div class="full-recipe__ingredients-wight">
                {{ item.attributes.nutrition_facts.fats }} г
              </div>
            </div>
            <div class="full-recipe__ingredients-item">
              <div class="full-recipe__ingredients-name">
                {{ $t("carbohydrates") }}
              </div>
              <div class="full-recipe__ingredients-wight">
                {{ item.attributes.nutrition_facts.carbonates }} г
              </div>
            </div>
          </div>

          <div class="full-recipe__calories">
            {{ $t("nutritionalValue") }}
          </div>

          <div class="full-recipe__complexity">
            <div class="full-recipe__complexity-item">
              <div class="full-recipe__complexity-title">
                {{ $t("timeKitchen") }}
              </div>
              <div
                v-if="item.attributes.ETA"
                class="full-recipe__complexity-time"
              >
                {{ item.attributes.ETA }}
              </div>
            </div>
            <recipe-difficulty
              v-if="item.attributes.difficulty"
              :difficultyNumber="item.attributes.difficulty"
            />
          </div>

          <ul v-if="item.attributes.steps.length" class="full-recipe__steps">
            <div class="full-recipe__steps-header">
              <div class="full-recipe__steps-title">
                {{ $t("cookingInstructions") }}
              </div>
              <div class="full-recipe__steps-print" @click="printStep">
                {{ $t("print") }}
              </div>
            </div>

            <li
              v-for="(step, index) in item.attributes.steps"
              :key="index"
              class="full-recipe__step"
            >
              <p class="full-recipe__step-descr">
                {{ step.text }}
              </p>
              <img
                v-if="getStepImage(step)"
                :src="getStepImage(step)"
                alt="recipe image"
                class="full-recipe__step-img"
              />
            </li>
          </ul>
          <!-- full-recipe__review-wrapper -->
        </div>

        <!-- full-recipe__review -->
      </div>

      <div v-if="products && products.length" class="full-recipe__products">
        <h2 class="full-recipe__products-title title">
          {{ $t("prescriptionItems") }}
        </h2>
        <products-list :items="products" />
      </div>

      <div class="full-recipe__socials">
        <div class="full-recipe__socials-title">
          {{ $t("shareFriends") }}
        </div>
        <div class="full-recipe__socials-links">
          <a
            :href="getShare('https://www.facebook.com/sharer.php?u=')"
            class="full-recipe__socials-link fb"
          ></a>
          <a
            :href="getShare('https://telegram.me/share/url?url=')"
            class="full-recipe__socials-link telegram"
          ></a>
        </div>
      </div>

      <recipe-recommendation-list
        :title="recipeTitle"
        :isPaginate="isPaginate"
        :excludeId="item.id"
      />
    </div>
  </div>
</template>

<script>
import { getRecipe } from "@/api/recipe";
import { getProductsByFilters } from "@/api/products";
export default {
  name: "FullCooking",
  components: {
    RecipeRecommendationList: () =>
      import("@/components/recipe/RecipeRecommendationList.vue"),
    RecipeDifficulty: () => import("@/components/recipe/RecipeDifficulty.vue"),
    ProductsList: () => import("@/components/products/ProductsList.vue"),
  },
  data() {
    return {
      item: null,
      recipeTitle: "otherRecipes",
      isPaginate: false,
      products: null,
      countPortion: 1,
      itemClone: null,
      categoriesIds: [],
      localMonth: {
        января: "yanvar",
        февраля: "fevral",
        марта: "mart",
        апреля: "aprel",
        мая: "may",
        июня: "iyun",
        июля: "iyul",
        августа: "avgust",
        сентября: "sentyabr",
        октября: "oktyabr",
        ноября: "noyabr",
        декабрь: "dekabr",
      },
    };
  },
  created() {
    getRecipe(this.$attrs.id).then((recipe) => {
      this.item = recipe;
      if (this.$i18n.locale === "uz") {
        getRecipe(this.item.attributes.localizations?.data[0]?.id).then(
          (recipeUz) => {
            this.item = recipeUz;
            this.getAll();
          }
        );
      }
      this.getAll();
    });
  },
  watch: {
    "$attrs.id": {
      handler(id) {
        getRecipe(id).then((recipe) => {
          this.item = recipe;
          if (this.$i18n.locale === "kz") {
            getRecipe(this.item.attributes.localizations?.data[0]?.id).then(
              (recipeKz) => {
                this.item = recipeKz;
                this.getAll();
              }
            );
          }
          this.getAll();
        });
      },
    },
  },
  computed: {
    getDate() {
      const dateFromStr = new Date(this.item?.attributes?.publishedAt);
      let dateRU = new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
        .format(dateFromStr)
        .replace("г.", "");
      let date = dateRU.split(" ");
      let dateUZ = date[0] + " " + this.localMonth[date[1]] + " " + date[2];
      if (this.$i18n.locale === "uz") {
        return dateUZ;
      } else {
        return dateRU;
      }
    },
    getImage() {
      return this.$helpers.getAbsolutePath(
        this.item?.attributes?.image?.data?.attributes?.url
      );
    },
  },
  methods: {
    printStep() {
      window.print();
    },
    getShare(item) {
      return item + window.location.href;
    },
    getAutorImage(item) {
      const urlImg =
        item?.attributes?.author?.data?.attributes?.avatar?.data?.attributes
          ?.url;
      return this.$helpers.getAbsolutePath(urlImg);
    },
    getStepImage(item) {
      const urlImg = item?.img?.data?.attributes?.url;
      return this.$helpers.getAbsolutePath(urlImg);
    },
    updateIngredient(type) {
      if (type === "plus") {
        this.countPortion++;
        this.item?.attributes?.recipe_details.forEach((p) => {
          if (p.quantityUpadated) {
            p.quantityUpadated = p.quantityUpadated + p.quantity;
          }
        });
      }
      if (type === "minus") {
        if (this.countPortion === 1) {
          this.countPortion = 1;
        } else {
          this.countPortion--;
          this.item?.attributes?.recipe_details.forEach((p) => {
            if (p.quantityUpadated) {
              p.quantityUpadated = p.quantityUpadated - p.quantity;
            }
          });
        }
      }
    },
    getQuantity() {
      this.item?.attributes?.recipe_details.forEach((p) => {
        p.quantityUpadated = p.quantity;
        switch (p.type) {
          case "gramms":
            p.typeIngredient = "typeIngredient.gramms";
            break;
          case "items":
            p.typeIngredient = "typeIngredient.items";
            break;
          case "volume_ml":
            p.typeIngredient = "typeIngredient.volume_ml";
            break;
          case "tea_spoons":
            p.typeIngredient = "typeIngredient.tea_spoons";
            break;
          case "spoons":
            p.typeIngredient = "typeIngredient.spoons";
            break;
          default:
            this.showMagnum = true;
            break;
        }
      });
    },
    getCatigoriesIds() {
      this.item?.attributes?.recipe_details.forEach((e) => {
        e.related_categories?.data.forEach((c) => {
          this.categoriesIds.push(c.id);
        });
      });
    },
    getProducts() {
      if (this.categoriesIds.length) {
        getProductsByFilters({
          categories: this.categoriesIds,
          discountTypes: [],
          shopTypes: [],
        }).then((categories) => {
          this.products = categories.data;
        });
      }
    },
    getAll() {
      this.getCatigoriesIds();
      this.getQuantity();
      this.getProducts();
    },
  },
};
</script>

<style scoped></style>
